$breakpoint-mobile-sm: 480px;
$breakpoint-mobile: 767px;
$breakpoint-tablet: 979px;
$breakpoint-desktop: 1200px;

$breakpoint-main-container: 1380px;

// mixins
@mixin dynamicTextSize($baseWidth, $baseFontSize: 16px, $minWidth: 320px) {
  @media (max-width: $baseWidth) and (min-width: $minWidth) {
    font-size: $baseFontSize / $baseWidth * 100vw;
  }
}

@mixin gap-space {
  padding-left: 2em;
  padding-right: 2em;
  @media all and (max-width: $breakpoint-mobile) {
    padding-left: 0.6em;
    padding-right: 0.6em;
  }
}
